@import "base/normalize";

:root {
  --body-color: #fff;
}

@font-face {
  font-family: 'Clash Display';
  font-weight: 400 500;
  src:  url(../fonts/ClashDisplay-Variable.woff2) format('woff2 supports variations'),
        url(../fonts/ClashDisplay-Variable.woff2) format('woff2-variations');
}

*, *:before, *:after {
  box-sizing: border-box;
}

.as-concentric {
  canvas {
    @media (max-width: 767px) {
      height: 35vh;
    }
  }
}

body {
  background: white;
  color: var(--body-color);
  font-family: 'Clash Display', sans-serif;

  .is-dark & {
    background: black;
  }
}

canvas {
  width: 100vw;
  height: 45vh;

  @media (min-width: 768px) {
    height: 75vh;
  }

  .as-grid & {
    width: 100vw;
    padding: 0 2vw;
  }
}

.container {
  margin: 0 calc(2vw + 8px);
}

header {
  padding: 24px 0;
  margin-top: 8px;
  
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    width: 120px;
  }

  .header-link{
    width: 120px;
    text-align: right;
  }

  em{
    font-weight: 500;
    font-style: normal;

    &:hover{
      text-decoration: underline;
    }

  }
}

.intro {
  padding: 24px 0 ;

  @media (min-width: 768px) {
    padding: 24px 0 ;
  }
}

h1{
  font-weight: 500;
  font-size: clamp(32px, 3.5vw, 48px);
  line-height: 1.15;
  max-width: 920px;

  @media (min-width: 768px) {
    width: 70vw;
  }
}

.button{
  margin: 48px 0;
  padding: 10px 20px;
  width: 275px;
  
  text-align: center;
  box-shadow: inset 0 0 0 4px currentColor;

  transition: box-shadow ease 600ms, color ease 200ms, background-color ease 400ms;

  &:hover{
    background-color: currentColor;
    
  }

  &:hover p{
    color: white;
  }

  p{
    color: currentColor;
    font-weight: 500;
    font-size: clamp(22px, 2vw, 28px);

  }
}

footer{
  margin: 100px 0 0;
  font-size: 12px;

  a{
    transition: all 400ms ease;
    &:hover{
      opacity: .4;
    }
  }
}


form{
  font-weight: 400;
  font-size: 24px;
  margin: 24px 0;


  button{
    font-weight: 500;
  }
}
